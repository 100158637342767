import React from 'react';
import { AppBar, Toolbar, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logoImage from '../../assets/Slide1.png';
import { Link } from 'react-router-dom';

const TopbarMobile = ({ toggleSidebar }) => {
  return (
    <AppBar position="fixed" color="inherit" elevation={0} sx={{ borderBottom: '1px solid #e0e0e0' }}>
      <Toolbar>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logoImage} alt="Logo" style={{ maxHeight: 76 }} />
          </Link>
        </Box>
        <IconButton
          edge="start"
          color="black"
          aria-label="menu"
          sx={{ p: 2, "& .MuiSvgIcon-root": { fontSize: "2rem" } }} // Adjust font size as needed
          onClick={toggleSidebar}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopbarMobile;
