import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Card, CardMedia, CardContent, CardActionArea, Button, useMediaQuery, useTheme } from '@mui/material';
import HeadWrapper from '../../components/HeadWrapper/HeadWrapper';
import TopbarLandingPage from '../../components/Topbar/TopbarLandingPage';
import BottomBar from '../../components/BottomBar/BottomBar';
import colors from '../../config/colors';
import logo from '../../assets/fullNameLogo.png';
import RankingsLandingPage from '../../assets/RankingsLandingPage.png';
import TradeCalculatorLandingPage from '../../assets/TradeCalculatorLandingPage.png';
import PickerLandingPage from '../../assets/PickerLandingPage.png';
import PlayerProfileLandingPage from '../../assets/PlayerProfileLandingPage.png';
import AnalyticsLandingPage from '../../assets/AnalyticsLandingPage.png';

// Define reusable styles
const styles = {
  sectionTitle: {
    fontFamily: 'Futura, sans-serif',
    fontWeight: 'bold',
    fontSize: {sm: '1.5rem', md: '2rem'}, // Set font size here instead of variant
  },
  bodyText: {
    color: 'text.secondary',
    fontFamily: 'Avenir, sans-serif',
    fontWeight: 'light',
    fontSize: {xs: '.9rem', sm: '1.15rem', md: '1.25rem'}, // Set font size here
  },
  smallBodyText: {
    color: 'text.secondary',
    fontFamily: 'Avenir, sans-serif',
    fontWeight: 'light',
    fontSize: {xs: '.6rem', sm: '.9rem', md: '1.15rem'}, // Set font size here
  },
  imageBox: {
    width: '100%',
    height: 'auto',
    borderRadius: '12px', 
    boxShadow: `0px 6px 15px 0px rgba(0, 0, 0, 0.2), 0px 4px 6px 0px rgba(0, 0, 0, 0.1)`,
    overflow: 'hidden',
  },
  centeredText: {
    textAlign: 'center',
    fontFamily: 'Avenir, sans-serif',
    fontWeight: 'bold',
    marginTop: '10px',
    color: 'text.primary',
    fontSize: '1.5rem', // Set font size here
  },
};

const LandingPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect screen size

  return (
    <>
      <HeadWrapper 
        title="Harry Knows Ball: Dynasty Baseball Rankings and Trade Calculator" 
        description="Your home for crowd-sourced dynasty fantasy baseball rankings, trade evaluations, and analytics tools!" 
        image={logo} 
      />
      <TopbarLandingPage />
      <Box 
        sx={{ 
          marginTop: {xs: '140px', sm: '200px'},
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          padding: '20px' 
        }}
      >
        <Typography 
          component="h1" 
          gutterBottom 
          sx={{ 
            fontWeight: 550,
            textAlign: 'center', 
            ...styles.sectionTitle,
            fontSize: {xs: '1.8rem', sm: '2.2rem', md: '2.8rem'}, // override style
          }}
        >
          Dynasty Baseball Made Easy.
        </Typography>
        
        {/* Sub text */}
        <Typography 
          component="p" 
          sx={{ 
            textAlign: 'center', 
            ...styles.bodyText,
          }}
        >
          Community driven player rankings and dynasty tools.
        </Typography>
        
        {/* Buttons with custom colors */}
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'center', 
            padding: '20px'
          }}
        >
          <Link to="/rankings" style={{ textDecoration: 'none' }}>
          <Button 
            variant="contained" 
            sx={{ 
              backgroundColor: colors.primary400, 
              color: '#fff',
              fontFamily: 'Avenir, sans-serif',
              marginRight: '10px',
              padding: {
                xs: '6px 12px',  // Extra small screens
                sm: '8px 16px',  // Small screens
                md: '10px 20px', // Medium and larger screens
              },
              fontSize: {
                xs: '0.8rem',  // Extra small screens
                sm: '0.9rem',      // Small screens
                md: '1rem',  // Medium and larger screens
              },
              '&:hover': {
                backgroundColor: colors.primary500,
              }
            }}
          >
            Rankings
          </Button>
          </Link>
          <Link to="/calculator" style={{ textDecoration: 'none' }}>
            <Button 
              variant="outlined" 
              sx={{ 
                borderColor: colors.primary400, 
                color: colors.primary400, 
                fontFamily: 'Avenir, sans-serif',
                padding: {
                  xs: '5px 10px',  // Extra small screens
                  sm: '7px 14px',  // Small screens
                  md: '9px 18px', // Medium and larger screens
                },
                fontSize: {
                  xs: '0.8rem',  // Extra small screens
                  sm: '0.9rem',      // Small screens
                  md: '1rem',  // Medium and larger screens
                },
                '&:hover': {
                  borderColor: colors.primary500,
                  color: colors.primary500,
                }
              }}
            >
              Trade Calculator
            </Button>
          </Link>
        </Box>
      </Box>
      {/* First Section - Rankings */}
      <Box 
        sx={{ 
          width: '100%',
          display: 'flex', 
          flexDirection: 'row', 
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: {xs: '75px', sm: '125px'},
          backgroundColor: 'white', 
          boxShadow: `0px 10px 40px 30px ${colors.secondary500}60`,
        }}
      >
        <Grid container spacing={0} maxWidth="lg" sx={{ width: '100%' }}>
          <Grid item xs={7}>
            <Box 
              component="img" 
              src={RankingsLandingPage} 
              alt="Rankings" 
              sx={{ 
                width: '100%', 
                clipPath: 'polygon(0 0, 100% 0, 85.7% 100%, 0% 100%)',
              }} 
            />
          </Grid>
          <Grid item xs={5}>
            <Box sx={{ padding: {xs: '5px 10px', sm: '20px', md: '30px'} }}>
              <Typography gutterBottom sx={styles.sectionTitle}>
                Rankings
              </Typography>
              <Typography sx={styles.smallBodyText}>
                {isSmallScreen
                  ? "Our player and pick rankings are crowd-sourced from thousands of user selections to ensure they reflect the most up-to-date, relevant information."
                  : "Our player and pick rankings are crowd-sourced from thousands of user selections, ensuring they reflect the most up-to-date values, helping you stay ahead of the competition and dominate your league."}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Second Section - Trade Calculator */}
      <Box 
        sx={{ 
          width: '100%', 
          display: 'flex', 
          flexDirection: 'row', 
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '-4px',
          backgroundColor: 'white', 
          boxShadow: `0 20px 40px ${colors.secondary500}60`,
        }}
      >
        <Grid container spacing={0} maxWidth="lg" sx={{ width: '100%' }}>
          <Grid item xs={5}>
            <Box sx={{ padding: {xs: '5px 10px', sm: '20px', md: '30px'} }}>
              <Typography gutterBottom sx={styles.sectionTitle}>
                Trade Calculator
              </Typography>
              <Typography sx={styles.smallBodyText}>
                {isSmallScreen
                  ? "The most accurate trade calculator on the market. "
                  : "We analyzed hundreds of dynasty baseball trades to bring you the most accurate trade calculator on the market. "}
                  Select any number of players and picks for two teams and see values fluctuate in real time!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box 
              component="img" 
              src={TradeCalculatorLandingPage} 
              alt="Trade Calculator" 
              sx={{ 
                width: '100%', 
                clipPath: 'polygon(14.3% 0, 100% 0, 100% 100%, 0% 100%)',
              }} 
            />
          </Grid>
        </Grid>
      </Box>

      {/* Third Section - Additional Pages */}
      <Typography component="h2" sx={{ ...styles.centeredText, fontFamily: 'Futura, sans-serif', padding: {xs: '20px', sm: '30px', md: '40px'} }}>
        Quick links
      </Typography>
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'row', 
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          paddingRight: {sm: '40px', md: '60px'},
          paddingLeft: {sm: '40px', md: '60px'},
          paddingBottom: '60px'
        }}
      >
        <Grid container spacing={{ xs: 2, sm: 3, md: 5 }} maxWidth="lg"  sx={{ width: '100%', justifyContent: 'center', padding: {xs: '10px 20px', sm: '20px 20px'} }}>
          {/* Player Profile */}
          <Grid item xs={4}>
            <Card>
              <CardActionArea component={Link} to="/rankings">
                <CardMedia
                  component="img"
                  image={PlayerProfileLandingPage}
                  alt="Player Profile Page"
                  sx={{ 
                    maxHeight: 190, 
                    objectFit: 'contain' // Ensures the image shrinks proportionally
                  }}
                />
                <CardContent sx={{ padding: {xs: '0px', sm: '2px', md: '4px'} }}>
                  <Typography component="p" sx={{ ...styles.centeredText, fontFamily: 'Futura, sans-serif', paddingBottom: '6px', fontSize: {xs: '.7rem', sm: '.90rem', md: '1.1rem'}  }}>
                    Player Profiles
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {/* Picker */}
          <Grid item xs={4}>
            <Card>
              <CardActionArea component={Link} to="/picker">
                <CardMedia
                  component="img"
                  image={PickerLandingPage}
                  alt="Picker Page"
                  sx={{ 
                    maxHeight: 190, 
                    objectFit: 'contain' // Ensures the image shrinks proportionally
                  }}
                />
                <CardContent sx={{ padding: {xs: '0px', sm: '2px', md: '4px'} }}>
                  <Typography 
                    component="p" 
                    sx={{ 
                      ...styles.centeredText, 
                      fontFamily: 'Futura, sans-serif', 
                      paddingBottom: '6px', 
                      fontSize: {xs: '.7rem', sm: '.9rem', md: '1.1rem'} 
                    }}>
                    Player Picker
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>


          {/* Analytics */}
          <Grid item xs={4}>
            <Card>
              <CardActionArea component={Link} to="/jwar">
                <CardMedia
                  component="img"
                  image={AnalyticsLandingPage}
                  alt="Analytics Page"
                  sx={{ 
                    maxHeight: 190, 
                    objectFit: 'contain' // Ensures the image shrinks proportionally
                  }}
                />
                <CardContent sx={{ padding: {xs: '0px', sm: '2px', md: '4px'} }}>
                  <Typography component="p" sx={{ ...styles.centeredText, fontFamily: 'Futura, sans-serif', paddingBottom: '6px', fontSize: {xs: '.7rem', sm: '.9rem', md: '1.1rem'}  }}>
                    JWAR Analytics
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <BottomBar isMobile={false} />
    </>
  );
};

export default LandingPage;
