import React from 'react';
import { Typography } from '@mui/material';
// Import Link from React Router if you're using it for routing
import { Link } from 'react-router-dom';

const Title = ({ pageName, extraText, showPickerLink, showRankingsLink }) => {
  const titleStyle = {
    textAlign: 'center',
    fontSize: '2rem',
    fontFamily: 'Futura, sans-serif', // Kept Futura as the primary font, with a fallback to sans-serif
    color: '#333', // Kept the text color, adjust as needed
  };

  // Style for the smaller text line below the title
  const subtitleStyle = {
    fontSize: '.9rem', // Smaller font size for the subtitle
    color: '#666', // Slightly lighter color for the subtitle for contrast
    fontFamily: 'Avenir', // Example secondary font, adjust as needed
  };

  return (
    <div style={{ textAlign: 'center', marginBottom: '24px', marginTop: '10px' }}> {/* Ensure the entire content is centered */}
      <Typography variant="h4" component="h1" style={titleStyle}>
        {pageName}
      </Typography>
      {extraText != null &&
        (<Typography component="p" style={{marginTop: '.7rem', ...subtitleStyle}}>
            {extraText}
        </Typography>)}
      {showPickerLink && 
        <Typography component="p" style={{marginTop: '.7rem', ...subtitleStyle}}>
            Like our site? Help contribute by making <Link to="/picker" style={{ color: '#007bff', textDecoration: 'none' }}>picks.</Link>
        </Typography>}
      {showRankingsLink &&
        <Typography component="p" style={{marginTop: '.7rem', ...subtitleStyle}}>
            Your picks inform our <Link to="/rankings" style={{ color: '#007bff', textDecoration: 'none' }}>rankings</Link>!
        </Typography>}

    </div>
  );
};

export default Title;
