const colors = {
    primary50: "#faf0f0", // [0, 4, 98]
    primary100: "#fad2d2", // [0, 16, 98]
    primary200: "#eba0a0", // [0, 32, 92]
    primary300: "#db7272", // [0, 48, 86]
    primary400: "#cc4949", // [0, 64, 80]
    primary500: "#ba2525", // [0, 80, 73]
    primary600: "#bd1c1c", // [0, 85, 74]
    primary700: "#940f0f", // [0, 90, 58]
    primary800: "#6b0505", // [0, 95, 42]
    primary900: "#3d0000", // [0, 100, 24]

    secondary100: "#fcfbf0", // [51, 5, 99]
    secondary200: "#fcf2b8", // [51, 27, 99]
    secondary300: "#fce874", // [51, 54, 99]
    secondary400: "#fcde30", // [51, 81, 99]
    secondary500: "#fcd703", // [51, 99, 99]
    secondary550: "#e6c402", // [51, 99, 90]
    secondary600: "#cfb002", // [51, 99, 81]
    secondary700: "#8a7501", // [51, 99, 54]
    secondary800: "#453b01", // [51, 99, 27]
    secondary900: "#0d0b00", // [51, 99, 5]

    success100: "#d7fad2", // [113, 16, 98]
    success200: "#b1f5a6", // [113, 32, 96]
    success300: "#8af07d", // [113, 48, 94]
    success400: "#65e854", // [113, 64, 91]
    success500: "#3de028", // [113, 82, 88]
    success600: "#2fbd1c", // [113, 85, 74]
    success700: "#1e940f", // [113, 90, 58]
    success750: "#18800a", // [113, 92, 50]
    success800: "#116b05", // [113, 95, 42]
    success900: "#073d00", // [113, 100, 24]

    warning100: "#fae1d2", // [22, 16, 98]
    warning200: "#f5c3a6", // [22, 32, 96]
    warning300: "#f0a77d", // [22, 48, 94]
    warning400: "#eb8c54", // [22, 64, 92]
    warning500: "#e6712e", // [22, 80, 90]
    warning600: "#bd571c", // [22, 85, 74]
    warning700: "#94400f", // [22, 90, 58]
    warning800: "#6b2b05", // [22, 95, 42]
    warning900: "#3d1600", // [22, 100, 24]

    danger100: "#fad2d2", // [0, 16, 98]
    danger200: "#f5a6a6", // [0, 32, 96]
    danger300: "#f07d7d", // [0, 48, 94]
    danger400: "#eb5454", // [0, 64, 92]
    danger500: "#e62e2e", // [0, 80, 90]
    danger600: "#bd1c1c", // [0, 85, 74]
    danger700: "#940f0f", // [0, 90, 58]
    danger800: "#6b0505", // [0, 95, 42]
    danger900: "#3d0000", // [0, 100, 24]

    information100: "#d2d3fa", // [238, 16, 98]
    information200: "#a6a9f5", // [238, 32, 96]
    information300: "#7d80f0", // [238, 48, 94]
    information400: "#5459eb", // [238, 64, 92]
    information500: "#2e34e6", // [238, 80, 90]
    information600: "#1c22bd", // [238, 85, 74]
    information700: "#0f1394", // [238, 90, 58]
    information800: "#05096b", // [238, 95, 42]
    information900: "#00023d", // [238, 100, 24]

    "SP": "#5463a1",
    "RP": "#547aa1",
    "C": "#9c5b3e",
    "1B": "#c39373",
    "2B": "#e0b162",
    "3B": "#d4595a",
    "SS": "#c13b3b",
    "OF": "#5e9a72",
    "UT": "#555763",

        // Darker and Lighter variations for positions
        "SP_Darker": "#4a578c",
        "SP_Lighter": "#5f6eb6",
        "SP_MuchLighter": "#899bcf",
        "RP_Darker": "#4a6e8c",
        "RP_Lighter": "#6086b6",
        "RP_MuchLighter": "#8ca9cf",
        "C_Darker": "#884d35",
        "C_Lighter": "#af6947",
        "C_MuchLighter": "#d49170",
        "1B_Darker": "#b18466",
        "1B_Lighter": "#d5a281",
        "1B_MuchLighter": "#e3b8a4",
        "2B_Darker": "#c6a255",
        "2B_Lighter": "#f8c36e",
        "2B_MuchLighter": "#ffe39b",
        "3B_Darker": "#bd4f4f",
        "3B_Lighter": "#ea6364",
        "3B_MuchLighter": "#ff8889",
        "SS_Darker": "#b03434",
        "SS_Lighter": "#d34242",
        "SS_MuchLighter": "#ff7474",
        "OF_Darker": "#537e64",
        "OF_Lighter": "#6eb480",
        "OF_MuchLighter": "#a1d7b0",
        "UT_Darker": "#4d5169",
        "UT_Lighter": "#5d5f7d",
        "UT_MuchLighter": "#8687a6",

    grey500: "#444647"
};

export default colors;