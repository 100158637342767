import { useState, useEffect } from 'react';
import { getSelectionsCount } from '../api/apiService';
import WebSocketService from '../socket/WebSocketService';
import useUserActivity from './useUserActivity';

const useSelectionsCount = () => {
  const [selectionsCount, setSelectionsCount] = useState('...');
  const [error, setError] = useState(false);
  const { isActive } = useUserActivity();

  const fetchSelectionsCount = async (callback) => {
    try {
      const response = await getSelectionsCount();
      setSelectionsCount(parseInt(response.data));
      setError(false);
      if (callback) callback();
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    if (isActive) {
      if (!WebSocketService.isConnected()) {
        // Pass WebSocket connect as a callback to fetchSelectionsCount
        fetchSelectionsCount(() => {
          WebSocketService.connect();
        });
      }
    } else {
      WebSocketService.disconnect();
    }
  }, [isActive]);

  useEffect(() => {
    const handleMessageReceived = (data) => {
      setSelectionsCount(parseInt(data, 10) || 0);
      setError(false);
    };

    WebSocketService.on('message-received', handleMessageReceived);
    // Initial fetch without callback, as WebSocket connection will be handled in the other useEffect if needed
    fetchSelectionsCount();

    return () => {
      WebSocketService.removeListener('message-received', handleMessageReceived);
      WebSocketService.disconnect();
    };
  }, []);

  return { selectionsCount, error };
};

export default useSelectionsCount;
