import React from 'react';
import { Box, Stack, Typography, Paper } from '@mui/material';
import colors from '../../config/colors';

const TotalTradeValue = ({ totalValue, depreciationValue }) => {

  // Define responsive font size similar to PlayerTradeItem
  const responsiveFontSize = {
    fontSize: {
      xs: '0.9rem', // For extra-small devices
      sm: '1rem', // For small devices
      md: '1rem', // For medium devices, and so on
      lg: '1.125rem', // You can adjust these values based on your design system
    },
    fontFamily: 'Avenir'
  };

    // Define responsive font size similar to PlayerTradeItem
    const responsiveFontSizePenalty = {
      fontSize: {
        xs: '0.8rem', // For extra-small devices
        sm: '.9rem', // For small devices
        md: '.9rem', // For medium devices, and so on
        lg: '1rem', // You can adjust these values based on your design system
      },
      fontFamily: 'Avenir'
    };

  return (
    <Paper variant="outlined" sx={{ mb: 0, width: '100%', border: 'none', borderBottom: '0px solid rgba(0, 0, 0, 0.12)' }}>
      <Box sx={{ p: 1, pb: 0 }}>
        {depreciationValue !== 0 && (<Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%', pb: 1.5 }}>
          {/* <IconButton aria-label="warning" disabled>
            <WarningAmberIcon sx={{ color: 'error.main' }} />
          </IconButton> */}
          <Typography variant="body1" color="text.secondary" component="div" sx={{ flexGrow: 1, ...responsiveFontSizePenalty }}>
            Package adjustment
            </Typography>
          <Typography variant="body1" color="text.secondary" sx={responsiveFontSizePenalty}>
            -{depreciationValue}
          </Typography>
        </Stack>)}
        <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
          <Typography variant="body1" component="div" sx={{ color: colors.secondary550, flexGrow: 1, fontWeight: 'bold', ...responsiveFontSize }}>
            Total value
          </Typography>
          <Typography variant="body1" sx={{ color: colors.secondary550, fontWeight: 'bold', ...responsiveFontSize }}>
            {totalValue}
          </Typography>
        </Stack>
      </Box>
    </Paper>
  );
};

export default TotalTradeValue;
