import React from 'react';
import { Box, Button, Typography, Container, Avatar, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import HeadWrapper from '../components/HeadWrapper/HeadWrapper';
import logo from '../assets/404.png';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import ListIcon from '@mui/icons-material/List';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import colors from '../config/colors';

const LandingPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <HeadWrapper 
        title="Harry Knows Ball" 
        description="Your home for crowd-sourced dynasty fantasy baseball rankings, trade evaluations, and analytics tools!" 
        image={logo} 
      />
      <Container maxWidth="md" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 64px)' }}>
        <Typography variant="h2" component="h1" gutterBottom color="black" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          <Link to="/picker" style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Logo" style={{ maxWidth: 350 }} />
          </Link>
        </Typography>
        <Typography 
          variant="h5" 
          component="p" 
          sx={{ 
            mb: 5, 
            textAlign: 'center', 
            color: 'text.secondary', 
            fontStyle: 'italic', 
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", 
            fontWeight: 'light', 
            letterSpacing: '0.0075em' 
          }}
        >
          Harry doesn't recognize this url! Check out our ~real~ pages.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', '& > *': { mx: 2 } }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar 
              sx={{ 
                bgcolor: 'primary.main', 
                mb: 2, 
                width: 64, 
                height: 64, 
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)' },
                  '50%': { transform: 'scale(1.1)' },
                  '100%': { transform: 'scale(1)' },
                },
                '&:hover': {
                  cursor: 'pointer',
                  bgcolor: 'primary.dark', // Slightly darker shade on hover
                }
              }} 
              onClick={() => handleNavigate('/picker')}>
              <SwipeVerticalIcon />
            </Avatar>
            {!smallScreen && (
              <Button variant="contained" size="large" color="primary" onClick={() => handleNavigate('/picker')} sx={{ minWidth: 200 }}>
                Rank players!
              </Button>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar 
              sx={{ 
                bgcolor: 'error.main', 
                mb: 2, 
                width: 64, 
                height: 64, 
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)' },
                  '50%': { transform: 'scale(1.1)' },
                  '100%': { transform: 'scale(1)' },
                },
                '&:hover': {
                  cursor: 'pointer',
                  bgcolor: 'error.dark', // Slightly darker shade on hover
                }
              }} 
              onClick={() => handleNavigate('/rankings')}>
              <ListIcon />
            </Avatar>
            {!smallScreen && (
              <Button variant="contained" size="large" color="error" onClick={() => handleNavigate('/rankings')} sx={{ minWidth: 200 }}>
                Rankings
              </Button>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar 
              sx={{ 
                bgcolor: colors.success700, 
                mb: 2, 
                width: 64, 
                height: 64, 
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)' },
                  '50%': { transform: 'scale(1.1)' },
                  '100%': { transform: 'scale(1)' },
                },
                '&:hover': {
                  cursor: 'pointer',
                  bgcolor: colors.success750, // Assuming success800 is a slightly darker shade
                }
              }} 
              onClick={() => handleNavigate('/calculator')}>
              <ShuffleIcon />
            </Avatar>
            {!smallScreen && (
              <Button variant="contained" size="large" onClick={() => handleNavigate('/calculator')} sx={{ minWidth: 220, backgroundColor: colors.success700, '&:hover': { bgcolor: colors.success750, } }}>
                Trade Calculator
              </Button>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar 
              sx={{ 
                bgcolor: colors.secondary550, 
                mb: 2, 
                width: 64, 
                height: 64, 
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)' },
                  '50%': { transform: 'scale(1.1)' },
                  '100%': { transform: 'scale(1)' },
                },
                '&:hover': {
                  cursor: 'pointer',
                  bgcolor: colors.secondary600, // Assuming success800 is a slightly darker shade
                }
              }} 
              onClick={() => handleNavigate('/jwar')}>
              <QueryStatsIcon />
            </Avatar>
            {!smallScreen && (
              <Button variant="contained" size="large" onClick={() => handleNavigate('/jwar')} sx={{ minWidth: 200, backgroundColor: colors.secondary550, '&:hover': { bgcolor: colors.secondary600, } }}>
                jWAR
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default LandingPage;
