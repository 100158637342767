import { useState, useEffect, useCallback } from 'react';

const useUserActivity = () => {
  const [isActive, setIsActive] = useState(document.visibilityState === 'visible');

  const handleVisibilityChange = useCallback(() => {
    setIsActive(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return { isActive };
};

export default useUserActivity;
