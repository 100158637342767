import React from 'react';
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails, List } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Title from '../Title/Title';
import colors from '../../config/colors';

import HeadWrapper from '../HeadWrapper/HeadWrapper';

import logo from '../../assets/Slide1.png';

// Define a reusable style object for Typography components
const typographyStyles = {
  fontWeight: '400',
  fontFamily: "Avenir",
  letterSpacing: '0.01em',
};

const faqs = {
  Picker: [
    {
      question: 'What is the HKB Picker?',
      answer: 'The Picker is the engine behind all of our player values. Every selection directly influences our rankings. If you like our site, the best way to help contribute is by making player selections using the Picker.'
    },
    {
      question: 'How does the 20 - 80 scale work?',
      answer: 'The 20 - 80 scale is based on the popular prospect grading system that assigns player values on a numeric scale: 20 being the worst and 80 the best. Similarly, when using the Picker, you will assign each of the four players a dynasty value grade between 20 and 80, with 20 being the worst and 80 the best among the group.'
    }
  ],
  Rankings: [
    {
      question: 'How are player values determined?',
      answer: `Harry Knows Ball uses your inputs along with a super fancy, top secret algorithm to generate player values. We take into account things like previous value, comparison grade and relative position to ensure our rankings stay up to date and flexible. \nThe lower value segment of our player set (< 200 points) is determined based on ownership % and not user selections. This section is meant to provide more options in our trade calculator and does not indicate consensus player values.`,
    },
    {
      question: 'What league format is used?',
      answer: `Harry Knows Ball assumes a 14 team league size using standard Fantrax Points scoring. Details include:`,
      additionalDetails: (
        <>
          <br />
          <Typography  sx={typographyStyles} variant="subtitle1" component="h3">Hitting</Typography>
          <ul>
            <li><Typography sx={typographyStyles}>Caught Stealing (CS): -1 point</Typography></li>
            <li><Typography sx={typographyStyles}>Doubles (2B): 2 points</Typography></li>
            <li><Typography sx={typographyStyles}>Home Runs (HR): 4 points</Typography></li>
            <li><Typography sx={typographyStyles}>Intentional Walks (IBB): 1 point</Typography></li>
            <li><Typography sx={typographyStyles}>Runs Batted In (RBI): 1 point</Typography></li>
            <li><Typography sx={typographyStyles}>Runs Scored (R): 1 point</Typography></li>
            <li><Typography sx={typographyStyles}>Singles (1B): 1 point</Typography></li>
            <li><Typography sx={typographyStyles}>Stolen Bases (SB): 2 points</Typography></li>
            <li><Typography sx={typographyStyles}>Triples (3B): 3 points</Typography></li>
            <li><Typography sx={typographyStyles}>Walks (BB): 1 point</Typography></li>
          </ul>
          <Typography  sx={typographyStyles} variant="subtitle1" component="h3">Pitching</Typography>
          <ul>
            <li>
              <Typography sx={typographyStyles}>Earned Runs Allowed (ER): Points vary by runs allowed per game (0: 7 points, 1: 5 points, 2: 3 points, 3: 2 points, 4: 1 point, ≥ 5: 0 points)</Typography>
            </li>
            <li>
              <Typography sx={typographyStyles}>Strikeouts Pitched (K): Points vary by strikeouts per game (0-5: 0 points, 6-12: 1-3 points incrementally, 13-18: 5 points, 19-26+: 7-10 points)</Typography>
            </li>
            <li><Typography sx={typographyStyles}>Wins (W): 3 points</Typography></li>
            <li><Typography sx={typographyStyles}>Save (SV): 3 points</Typography></li>
            <li>
              <Typography sx={typographyStyles}>Hits + Walks Allowed (H+BB): Points vary by total per game (0: 20 points, 1-2: 16-12 points, 3-5: 8-4 points, 6-10: 4-2 points, 11-12: 1 point, ≥13: 0 points)</Typography>
            </li>
          </ul>
        </>
      ),
    },
    {
      question: 'Why don\'t I see my players?',
      answer: `Harry knows ball aggregates player data from consensus rankings and ownership percentages. If a lesser known player is not included please check back periodically as our player set refreshes weekly.`
    }
  ],
  'Trade Calculator': [
    {
      question: 'How should I use the Trade Calculator?',
      answer: `The Trade Calculator should be used as a tool for evaluating dynasty trade offers. It is meant to serve as a single data point rather than the entire evaluation. As always, know your league mates and understand how your unique league and scoring settings might sway value one way or another.`,
    },
    {
      question: 'What is package adjustment?',
      answer: `Package adjustment serves to penalize using lower value player packages to acquire a single high value player. It puts a premium on higher value players.`,
    }
  ],
  JWAR: [
    {
      question: 'jWAR - What is it good for?',
      answer: `League context matters, especially when determining a player’s value. jWAR’s goal is to incorporate your unique league settings into determining a player’s actual and projected value.`
    },
    {
      question: 'How does jWAR work?',
      answer: `jWAR is essentially the traditional WAR statistic, just adjusted for fantasy purposes. It attempts to solve for the wins a player will contribute to a fantasy team over what a “replacement level player” would generate at that same position.`
    },
    {
      question: 'What is the main advantage of using jWAR?',
      answer: (
        <>
          jWAR is a tool to compare relative value among all players, regardless of position. It accounts for positional advantage and positional scarcity as well as providing relative values for comparing position players vs pitchers.
          <br /><br />For example, a SS that avg. 4.5pts/game may be more valuable than a OF that scores 5pts/game because of positional scarcity and/or because the average pts for a starting SS is lower than OF.
          <br /><br />jWAR should be used when determining who to target in drafts and what trades would increase your team’s overall chances of winning.
        </>
      ),
    },
    {
      question: 'About JP and jWAR',
      answer: (
        <>
          JP is a lifelong baseball fan, dynasty nerd, and currently works as a Data Scientist. He previously worked for an MLB team doing business strategy/analytics. Despite leaving baseball, he still keeps himself close to the game by making it his mission to visit every MLB ballpark.
          <br /><br />jWAR is JP’s brainchild that evolved from the countless hours he spent attempting to dominate his fellow league mates, which ultimately led him to winning the 2023 league championship.
        </>
      ),
    }
  ],
  General: [
    {
      question: 'What\'s next?',
      answer: `Our team is continuously working on enhancements and will release new features on an ongoing basis. Please follow us on X at @HKBRankings to stay up to date. Some priorities include team and league evaluations, including more interactive injury and prospect filters, and integrating other analytics tools. Please contact us if you have any features you would like to see!`,
    },
    {
      question: 'Who is Harry?',
      answer: `Harry of Harry Knows Ball is indeed a dynasty baseball loving software engineer. He spends his free time watching his beloved Padres and drinking IPAs, win or lose. He has a dog named after Fernando Tatis Jr. and a strong distaste for Jon Heyman and Ken Rosenthal.`,
    },
    {
      question: 'Contact Info',
      answer: `To contact the HKB team please DM us on X at @HKBRankings or email us at harryknowsballteam@gmail.com`,
    }
  ],
};

const FAQSection = ({ title, faqs }) => (
  <>
    <Box sx={{ padding: '10px 16px' }}>
      <Typography variant="h6" color={colors.primary400} style={{ fontFamily: 'Futura, sans-serif' }}>{title}</Typography>
    </Box>
    <List>
      {faqs.map((faq, index) => (
        <Accordion 
          sx={{
            mb: 2,
            boxShadow: 'none', 
            borderBottom: '1px solid rgba(0, 0, 0, .125)', 
            '&:before': {
              display: 'none',
            },
            '&.Mui-expanded': {
              margin: 'auto',
            },
            '& .MuiAccordionSummary-root': {
              borderBottom: 0,
            },
            '& .MuiAccordionDetails-root': {
              borderTop: 0,
            }
          }} 
          key={index}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
            <Typography sx={typographyStyles}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={typographyStyles}>
              {faq.answer}
            </Typography>
              {faq.additionalDetails}
          </AccordionDetails>
        </Accordion>
      ))}
    </List>
  </>
);

const FAQ = () => {
  return (
    <>
      <HeadWrapper 
        title="Frequently Asked Questions" 
        description="Frequently asked questions for Harry Knows Ball." 
        image={logo} 
      />
      <Title pageName="FAQ" showPickerLink={true} showRankingsLink={false}  />
      <Container maxWidth="sm" sx={{ py: 0, display: 'flex', flexDirection: 'column', gap: 0 }}>
        {Object.entries(faqs).map(([title, faqItems]) => (
          <FAQSection key={title} title={title} faqs={faqItems} />
        ))}
      </Container>
    </>
  );
};

export default FAQ;
