import { useState, useEffect } from 'react';
import { getPlayerRankings } from '../api/apiService';

// Custom hook to fetch player rankings
const usePlayerRankings = () => {
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('...');

  useEffect(() => {
    getPlayerRankings()
      .then(response => {
        const playersWithIndex = response.data.map((player, index) => ({
            ...player,
            originalIndex: index
        }));
        setPlayers(playersWithIndex);

        const now = new Date();
        const options = { hour: '2-digit', minute: '2-digit', hour12: true, timeZoneName: 'short' };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(now);
        
        // Custom processing to remove leading zero from the hour part
        const hourPart = parts.find(part => part.type === 'hour').value;
        const minutePart = parts.find(part => part.type === 'minute').value;
        // Convert hour to number then back to string to remove leading zero if present
        const hourFormatted = String(parseInt(hourPart, 10));
        const time = `${hourFormatted}:${minutePart}`;
        
        const amPm = parts.find(part => part.type === 'dayPeriod').value;
        const timeZone = parts.find(part => part.type === 'timeZoneName').value;

        // Format lastUpdated as "time AM/PM ET"
        setLastUpdated(`${time} ${amPm} ${timeZone}`);

        // Format lastUpdated as "time AM/PM ET"
        setLastUpdated(`${time} ${amPm} ${timeZone}`);

        setError(false);
      })
      .catch(error => {
        console.error("Failed to fetch players:", error);
        setLastUpdated('...');
        setError(true);
      });
  }, []);

  return { players, error, lastUpdated };
};

export default usePlayerRankings;
