import React, { useState, useEffect } from 'react';
import { Tooltip, Typography, Box } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

const PlayerInfoTooltip = ({ value7DaysAgo, currentValue, children }) => {
    const [open, setOpen] = useState(false);

    const handleTooltipToggle = (event) => {
        // Prevents the default action to ensure the click is handled properly
        event.stopPropagation();
        setOpen(!open);
    };

    // Detects touch device
    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    };

    // Close the tooltip when clicking anywhere else on the screen
    useEffect(() => {
        if (isTouchDevice()) {
            const closeTooltip = () => setOpen(false);
            // Only add this listener when the tooltip is open
            if (open) {
                document.addEventListener('click', closeTooltip);
            }
            return () => {
                document.removeEventListener('click', closeTooltip);
            };
        }
    }, [open]);

    const renderValueChangeIcon = () => {
        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                }}
            >
                <EastIcon fontSize="small" sx={{ color: 'white' }} />
            </Box>
        );
    };

    const getDateNDaysAgo = (daysAgo) => {
        const date = new Date();
        date.setDate(date.getDate() - daysAgo);
      
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
      
        return `${month}-${day}`;
    };

    return (
        <Tooltip
            placement="top"
            title={
                <React.Fragment>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1, px: '.5px' }}>
                        <Typography variant="body2">{getDateNDaysAgo(7)}</Typography>
                        <Typography variant="body2">{getDateNDaysAgo(0)}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
                        <Typography variant="body2">{value7DaysAgo}</Typography>
                        {renderValueChangeIcon()}
                        <Typography variant="body2">{currentValue}</Typography>
                    </Box>
                </React.Fragment>
            }
            onClick={isTouchDevice() ? handleTooltipToggle : undefined}
            onMouseEnter={!isTouchDevice() ? () => setOpen(true) : undefined}
            onMouseLeave={!isTouchDevice() ? () => setOpen(false) : undefined}
            open={open}
            arrow
        >
            <div onClick={isTouchDevice() ? handleTooltipToggle : undefined} style={{ display: 'flex', alignItems: 'center' }}>
                {children}
            </div>
        </Tooltip>
    );
};

export default PlayerInfoTooltip;
