import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Box, Typography } from '@mui/material';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import ListIcon from '@mui/icons-material/List';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import colors from '../../config/colors';

const SidebarMobile = () => {
  const renderSection = (title, items) => (
    <>
      <Box sx={{ padding: '10px 16px' }}>
        <Typography variant="h6" color={colors.primary400} style={{ fontFamily: 'Futura, sans-serif' }}>
          {title}
        </Typography>
      </Box>
      <List>
        {items.map(({ path, text, icon }, index) => (
          <ListItem button component={Link} to={path} key={text}>
            <ListItemIcon style={{ color: colors.grey500 }}>{icon}</ListItemIcon>
            <ListItemText disableTypography primary={text} style={{ fontFamily: 'Futura, sans-serif', color: colors.grey500 }} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </>
  );

  const sections = [
    {
      title: 'Dynasty',
      items: [
        { path: '/picker', text: 'Picker', icon: <SwipeVerticalIcon /> },
        { path: '/rankings', text: 'Rankings', icon: <ListIcon /> },
        { path: '/calculator', text: 'Trade Calculator', icon: <ShuffleIcon /> }
      ]
    },
    {
      title: 'Analytics',
      items: [
        { path: '/jwar', text: 'JWAR', icon: <QueryStatsIcon /> }
      ]
    },
    {
      title: 'General',
      items: [
        { path: '/faq', text: 'FAQ', icon: <InfoIcon /> }
      ]
    }
  ];

  return (
    <Drawer
      sx={{
        width: '100%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
          boxSizing: 'border-box',
          marginTop: '76px',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Divider />
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          {renderSection(section.title, section.items)}
        </React.Fragment>
      ))}
    </Drawer>
  );
};

export default SidebarMobile;
