import React, { useState } from 'react';
import { Box, Button, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PositionSelectionFilter from './PositionSelectionFilter';
import colors from '../../config/colors'; // Adjust the import path as needed

const JWarFilters = ({ selectedPositions, onPositionChange, clearFilters }) => {
  const [open, setOpen] = useState(false);

  const handleToggleFilters = () => {
    setOpen(!open);
  };

  const dynamicTextSize = {
    fontSize: {
      xs: '0.6rem',
      sm: '0.75rem',
      md: '0.875rem',
    },
  };

  const fontStyles = {
    fontFamily: 'Avenir, Arial, sans-serif',
  };

  const buttonStyles = {
    ...fontStyles,
    ...dynamicTextSize,
  };

  const filterButtonColor = selectedPositions.length > 0 ? colors.secondary600 : 'primary.main';
  const borderColor = selectedPositions.length > 0 ? colors.secondary550 : 'grey';
  const borderStyle = {
    border: open ? `1px solid ${borderColor}` : 'none',
    borderRadius: '4px',
  };

  return (
    <Box sx={borderStyle}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '6px' }}>
        <Button 
          onClick={handleToggleFilters} 
          startIcon={<ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }} />} 
          sx={{ color: filterButtonColor, ...buttonStyles }}
        >
          Filters
        </Button>
      </Box>
      <Collapse in={open}>
        <PositionSelectionFilter
          title="Position"
          selectedOptions={selectedPositions}
          onOptionChange={onPositionChange}
          sx={{ ...fontStyles }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '8px' }}>
          <Button onClick={clearFilters} sx={{ ...buttonStyles, color: filterButtonColor }}>
            Clear
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
};

export default JWarFilters;
