import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import EventEmitter from 'events';

class WebSocketService extends EventEmitter {
    static DEFAULT_URL = 'http://localhost:8080';
    static TOPIC_SELECTIONS_COUNT = '/topic/selectionsCount';
    static MAX_RETRIES = 5; // Maximum number of retries
    static RETRY_INTERVAL = 60000; // Retry every minute

    constructor() {
        super();
        this.stompClient = null;
        this.connected = false;
        this.serverUrl = (process.env.REACT_APP_API_URL || WebSocketService.DEFAULT_URL) + "/ws";
        this.retryCount = 0;

        // Binding all methods to ensure 'this' context
        this.connect = this.connect.bind(this);
        this.onConnected = this.onConnected.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.onError = this.onError.bind(this);
        this.retryConnection = this.retryConnection.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.isConnected = this.isConnected.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    connect() {
        const ws = new SockJS(this.serverUrl);
        this.stompClient = Stomp.over(ws);
        this.stompClient.connect({}, () => {
            this.onConnected();
            this.connected = true;
            this.retryCount = 0;
        }, this.onError);
    }

    onConnected() {
        if (this.stompClient && this.stompClient.connected) {
            this.stompClient.subscribe(WebSocketService.TOPIC_SELECTIONS_COUNT, this.onMessageReceived);
        } else {
            console.error("Attempted to subscribe, but STOMP client is not connected or is null.");
        }
    }

    onMessageReceived(message) {
        console.debug("Message Received: ", message.body);
        this.emit('message-received', message.body);
    }

    onError(error) {
        console.error('WebSocket connection error. Attempting to reconnect...', error);
        this.connected = false;
        this.retryConnection();
    }

    retryConnection() {
        if (this.retryCount < WebSocketService.MAX_RETRIES) {
            setTimeout(() => {
                console.log(`Attempting to reconnect... (${this.retryCount + 1})`);
                this.connect();
                this.retryCount++;
            }, WebSocketService.RETRY_INTERVAL);
        } else {
            console.error("Maximum retry attempts reached, please check the server status.");
        }
    }

    disconnect() {
        if (this.stompClient && this.connected) {
            this.stompClient.disconnect();
            console.log("Disconnected from WebSocket.");
            this.connected = false;
        }
    }

    isConnected() {
        return this.connected;
    }

    sendMessage(topic, msg) {
        if (this.connected) {
            this.stompClient.send(topic, {}, msg);
        } else {
            console.error("Cannot send message. Connection not established.");
        }
    }
}

export default new WebSocketService();
