import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, ListItem, Box, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material';
import SidebarMobile from '../../components/Sidebar/SidebarMobile';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import ListIcon from '@mui/icons-material/List';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import colors from '../../config/colors';
import logoImage from '../../assets/fullNameLogo.png';

const TopbarLandingPage = ({ toggleSidebarMobile }) => {
  const [dropdown, setDropdown] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect screen size

  const [mobileSidebarVisible, setMobileSidebarVisible] = useState(true); // for mobile drop down

  const toggleMobileSidebar = () => setMobileSidebarVisible(!mobileSidebarVisible);

  const handleDropdownToggle = (name) => {
    setDropdown((prev) => (prev === name ? null : name));
  };

  const location = useLocation(); // Get current location

  // Use useEffect to set mobileSidebarVisible to false on route change
  useEffect(() => {
    setMobileSidebarVisible(false); // Close the sidebar on every route change
  }, [location]); // Dependency array on location change

  const dynastyItems = [
    { path: '/picker', text: 'Picker', icon: <SwipeVerticalIcon /> },
    { path: '/rankings', text: 'Rankings', icon: <ListIcon /> },
    { path: '/calculator', text: 'Trade Calculator', icon: <ShuffleIcon /> },
  ];

  const analyticsItems = [
    { path: '/jwar', text: 'JWAR', icon: <QueryStatsIcon /> },
  ];

  const generalItems = [
    { path: '/faq', text: 'FAQ', icon: <InfoIcon /> },
  ];

  const allMenuItems = [
    ...dynastyItems,
    ...analyticsItems,
    ...generalItems,
  ];

  const renderMenuItems = (items) => (
    <Box
      className="custom-dropdown"
      sx={{
        marginTop: '23px',
        padding: '5px',
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: `0px 10px 40px ${colors.secondary500}60`, // Longer, less opaque shadow on the bottom and sides
        zIndex: 1000,
        minWidth: '150px',
        position: 'absolute',
      }}
    >
      {items.map(({ path, text, icon }) => (
        <Link
          key={text}
          to={path}
          className="dropdown-item"
          style={{ textDecoration: 'none' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              backgroundColor: 'transparent',
              color: colors.grey500,
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                backgroundColor: colors.secondary300,
                transform: 'scale(1.08)',
              },
            }}
          >
            <Box sx={{ color: colors.grey500 }}>{icon}</Box>
            <Typography sx={{ marginLeft: '10px', fontFamily: 'Futura, sans-serif' }}>
              {text}
            </Typography>
          </Box>
        </Link>
      ))}
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: `0px 30px 60px ${colors.secondary500}70`,
      }}
    >
        {mobileSidebarVisible ? <SidebarMobile /> : null}
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Logo */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px',
            borderRight: '1px solid rgba(0, 0, 0, .125)',
          }}
        >
          <ListItem
            button
            component={Link}
            to="/"
            key="LandingPage-topbar"
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <img src={logoImage} alt="Logo" style={{ maxHeight: 50 }} />
          </ListItem>
        </Box>

        {/* Conditionally Render MenuIcon for Small Screens */}
        {isSmallScreen ? (
          <IconButton
          edge="start"
          color="black"
          aria-label="menu"
          sx={{ p: 1, "& .MuiSvgIcon-root": { fontSize: "2rem" } }} // Adjust font size as needed
          onClick={toggleMobileSidebar}
        >
          <MenuIcon />
        </IconButton>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 2,
              flexGrow: 1,
              padding: '10px',
            }}
          >
            {/* Dynasty Dropdown */}
            <Box sx={{ position: 'relative' }}>
              <IconButton
                onClick={() => handleDropdownToggle('dynasty')}
                color="inherit"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: colors.grey500,
                    fontFamily: 'Futura, sans-serif',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Dynasty
                </Typography>
                <ExpandMoreIcon
                  sx={{
                    color: colors.grey500,
                    transition: 'transform 0.3s ease',
                    transform: dropdown === 'dynasty' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </IconButton>
              {dropdown === 'dynasty' && renderMenuItems(dynastyItems)}
            </Box>

            {/* Analytics Dropdown */}
            <Box sx={{ position: 'relative' }}>
              <IconButton
                onClick={() => handleDropdownToggle('analytics')}
                color="inherit"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: colors.grey500,
                    fontFamily: 'Futura, sans-serif',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Analytics
                </Typography>
                <ExpandMoreIcon
                  sx={{
                    color: colors.grey500,
                    transition: 'transform 0.3s ease',
                    transform: dropdown === 'analytics' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </IconButton>
              {dropdown === 'analytics' && renderMenuItems(analyticsItems)}
            </Box>

            {/* General Dropdown */}
            <Box sx={{ position: 'relative' }}>
              <IconButton
                onClick={() => handleDropdownToggle('general')}
                color="inherit"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: colors.grey500,
                    fontFamily: 'Futura, sans-serif',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  General
                </Typography>
                <ExpandMoreIcon
                  sx={{
                    color: colors.grey500,
                    transition: 'transform 0.3s ease',
                    transform: dropdown === 'general' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </IconButton>
              {dropdown === 'general' && renderMenuItems(generalItems)}
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopbarLandingPage;
