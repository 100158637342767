import React, { useState } from 'react';
import { Box, Stack, Autocomplete, TextField, Typography, useTheme } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import PlayerTradeItem from './PlayerTradeItem';
import TotalTradeValue from './TotalTradeValue';

const TradePackageBuilder = ({
  playerRankings,
  teamNumber,
  setTeamPlayers,
  teamPlayers,
  teamValue,
  depreciationValue,
  success
}) => {

  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [dropdownToggle, setDropdownToggle] = useState(true);

  const theme = useTheme();

  const responsiveFontSize = {
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      md: '1rem',
      lg: '1.125rem',
    },
    fontFamily: 'Avenir'
  };

  const handleAddPlayerToTeam = (player, team) => {
    setDropdownToggle(!dropdownToggle);
    if (player && !teamPlayers.some(p => p.name === player.name)) {
      setTeamPlayers(prev => [...prev, player]);
    }
  };

  const handleDeletePlayerFromTeam = (playerToDelete, team) => {
    setTeamPlayers(teamPlayers.filter(player => player.name !== playerToDelete.name));
  };

  const customFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.name,
    limit: 10
  });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: 1,
      width: '100%',
      minWidth: '300px',
      [theme.breakpoints.down('md')]: {
        minWidth: '100%',
      },
    }}>
      <Autocomplete
        options={playerRankings}
        open={openAutocomplete}
        onClose={() => setOpenAutocomplete(false)}
        onInputChange={(event, value) => {
          setOpenAutocomplete(value.length > 0);
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Team ${teamNumber} receives`}
            InputLabelProps={{
              sx: {
                fontFamily: 'Avenir',
                fontWeight: 500
              }
            }}
          />
        )}
        onChange={(event, newValue) => handleAddPlayerToTeam(newValue, teamNumber)}
        sx={{ width: '100%', marginLeft: 0 }}
        key={`teamPlayers-${teamNumber}-${dropdownToggle}`}
        filterOptions={customFilterOptions}
        renderOption={(props, option) => {
          const { key, ...otherProps } = props;
          return (
            <li key={key} {...otherProps}>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
                <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1, ...responsiveFontSize }}>
                  {option.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ ...responsiveFontSize }}>
                  {option.value}
                </Typography>
              </Stack>
            </li>
          );
        }}
      />
      {teamPlayers.length > 0 && 
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          width: '100%', 
          borderRadius: '8px', 
          padding: '16px', 
          marginTop: '8px',
          boxSizing: 'border-box',
          boxShadow: success ? '0 0 20px rgba(0, 128, 0, 0.5)' : '0 0 20px rgba(255, 0, 0, 0.2)'
        }}>
          {teamPlayers.map((player) => (
            <PlayerTradeItem key={`${player.name}-${teamNumber}`} player={player} onDelete={() => handleDeletePlayerFromTeam(player, teamNumber)} />
          ))}
          <TotalTradeValue totalValue={teamValue} depreciationValue={depreciationValue} />
        </Box>
      }
    </Box>
  );
  
};

export default TradePackageBuilder;
