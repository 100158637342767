import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Snackbar, Link } from '@mui/material';

const CookiesPopup = () => {
    const [open, setOpen] = useState(false);

    const fontStyles = {
        fontFamily: 'Avenir, Arial, sans-serif',
        fontWeight: 500,
        color: '#FFFFFF',
        fontSize: {
            xs: '0.7rem',
            sm: '0.825rem',
            md: '.95rem',
        }
    };

    const buttonStyles = {
        fontFamily: 'Avenir, Arial, sans-serif',
        fontWeight: 500,
        color: '#FFFFFF',
        fontSize: {
            xs: '0.7rem',
            sm: '0.825rem',
            md: '.95rem',
        }
    };

    const snackbarStyles = {
        backgroundColor: '#333333',
        border: '2px solid #FFFFFF'
    };

    useEffect(() => {
        const acknowledgedCookies = localStorage.getItem('acknowledgedCookies');
        if (!acknowledgedCookies) {
            setOpen(true);
        }
    }, []);

    const handleAck = () => {
        localStorage.setItem('acknowledgedCookies', 'true');
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            ContentProps={{
                sx: snackbarStyles,
            }}
            message={
                <Box>
                    <Typography sx={fontStyles}>
                        We use cookies to enhance your browsing experience and enable site analytics. Learn more about cookies and how to disable them <Link href="https://www.cookiesandyou.com/" target="_blank" rel="noopener noreferrer" sx={{ ...buttonStyles, textDecoration: 'underline', textDecorationColor: '#FFFFFF' }}>here</Link>.
                    </Typography>
                </Box>
            }
            action={
                <Button color="primary" 
                    sx={{ 
                        ...buttonStyles,
                        border: '1px solid #FFFFFF',
                        borderRadius: '4px' 
                    }} 
                    onClick={handleAck}>
                    Continue
                </Button>
            }
        />
    );
};

export default CookiesPopup;
