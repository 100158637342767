import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import colors from '../../config/colors'; // Adjust the import path as needed

const FloatingSelectionFilter = ({ title, options, selectedOption, handleSelect, shrinkGap=false }) => {
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };

  const dynamicTitleSize = {
    fontSize: {
      xs: '0.75rem', // smaller text for extra-small devices
      sm: '0.875rem', // default text size for small devices and up
      md: '1rem', // slightly larger text for medium devices and up
    },
  };

  const fontStyles = {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontWeight: '600',
  };

  const buttonStyles = {
    ...fontStyles,
    fontSize: 'clamp(9px, 1.5vw, 15px)',
    color: '#6D6D6D',
  };

  const handleButtonClick = (option) => {
    if (selectedOption === option) {
      handleSelect(''); // Unselect if the same option is clicked
    } else {
      handleSelect(option); // Select the new option
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', py: '8px' }}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}> {/* Keep toggle buttons centered */}
        {title && <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', padding: '8px', paddingBottom: '4px' }}>
          <Typography variant="subtitle2" color="#6D6D6D" sx={{ ...dynamicTitleSize, ...fontStyles }}>
            {title}
          </Typography>
        </Box>}
        <Box sx={{
          display: 'flex',
          gap: shrinkGap ? 1 : 2,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row'
        }}>
          {options.map((option) => (
            <Button
              key={option}
              onClick={() => handleButtonClick(option)}
              variant="outlined"
              sx={{
                borderRadius: '8px', // Rounded rectangular shape
                boxShadow: 'none', // Removes shadow
                border: 1,
                borderColor: colors.secondary500,
                backgroundColor: colors.secondary100,
                ...buttonStyles,
                ...(isTouchDevice() === false ? {
                  ':hover': {
                    backgroundColor: colors.secondary200,
                    borderColor: colors.secondary500,
                  }
                } : {
                  ':hover': {
                    backgroundColor: colors.secondary100,
                    borderColor: colors.secondary500,
                  }
                }),
                ...(selectedOption === option && {
                  backgroundColor: colors.secondary500,
                  color: 'grey',
                  ...(isTouchDevice() === false ? {
                    ':hover': {
                      backgroundColor: colors.secondary550,
                      borderColor: colors.secondary500,
                    },
                  } : {
                    ':hover': {
                      backgroundColor: colors.secondary500,
                      borderColor: colors.secondary500,
                    },
                  }),
                }),
              }}
            >
              {option}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default FloatingSelectionFilter;
