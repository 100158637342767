import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import Title from '../../components/Title/Title';
import colors from '../../config/colors';

const PrivacyPolicyPage = () => {

  return (
    <Box className="privacy-policy" sx={{ fontFamily: 'Avenir, sans-serif' }}>
      <Title pageName="Privacy Policy" showPickerLink={false} showRankingsLink={false} />

      <Typography variant="body2" gutterBottom sx={{ fontFamily: 'Avenir, sans-serif' }}>
        <strong>Effective Date:</strong> August 22, 2024
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        At <strong>HarryKnowsBall.com</strong>, we are committed to protecting your privacy. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to ensure your information is secure. By using our website, you agree to the practices described in this policy.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        1. Information We Collect
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        We do not collect any personal data from users. You can browse HarryKnowsBall.com without providing any personal information.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        2. Cookies and Tracking Technologies
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        We do not use cookies or other tracking technologies, except those used by Google Analytics, which helps us understand how our website is used.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        3. Third-Party Services
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        We use Google Analytics to track and analyze the use of our services. Google Analytics may collect and process certain data about your visit to our website.
      </Typography>
      <ul style={{ fontFamily: 'Avenir, sans-serif' }}>
        <li>
          <Link href="https://tools.google.com/dlpage/gaoptout" target="_blank">
            Google Analytics Opt-Out
          </Link>: Use this link to opt out of being tracked by Google Analytics.
        </li>
        <li>
          <Link href="https://policies.google.com/privacy" target="_blank">
            Google Privacy & Terms
          </Link>: Use this link for more information on Google’s privacy policy and terms.
        </li>
        <li>
          <Link href="https://policies.google.com/technologies/cookies" target="_blank">
            Google Cookie Policy
          </Link>: Use this link for more information on Google Analytics' cookie policy.
        </li>
      </ul>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        4. User Communication
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        We do not offer newsletters, contact forms, or other forms of user communication, and therefore, we do not collect any communication-related data.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        5. Children’s Privacy
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        Our website is not directed at or intended for children under the age of 13. We do not knowingly collect any personal information from children. If we become aware that we have inadvertently received personal information from a child under the age of 13, we will delete such information from our records.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        6. Data Security
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        We take the security of our website seriously. We monitor application logs, run our code through security scanners, and ensure that our site is encrypted using HTTPS. While we strive to use commercially acceptable means to protect your information, we cannot guarantee absolute security.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        7. Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        We reserve the right to update or modify this Privacy Policy at any time without prior notice. The date of the most recent update will be indicated at the top of this document. Continued use of the website after any changes constitutes your acceptance of the new Privacy Policy.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        8. Contact Information
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        If you have any questions or concerns about this Privacy Policy, please contact us via email at <Link href="mailto:harryknowsballteam@gmail.com">harryknowsballteam@gmail.com</Link>.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicyPage;
